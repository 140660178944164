import PerfectScrollbar from 'perfect-scrollbar'
$(document).ready(function() {
   let btns = $('.project-btn');
   const cont = document.querySelectorAll('.projects-content');
       if(cont.length > 0) {
        const ps = new PerfectScrollbar(cont[0])
       }

   btns.click(function () {
       btns.removeClass('active-button');
       $(this).addClass('active-button');

       let sec = $(this).attr('data-btn');

       let secTop = $('#'+sec)[0].offsetTop;
       cont[0].scrollTop = secTop;
   });

});
