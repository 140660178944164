window.swipe = function (item, el) {
    item.click(function () {
        for (let i = 0; i < item.length;i++) {
            item.find(el).slideUp('slow')
        };

        if($(this).hasClass('swipe-open')) {
            $(this).find(el).slideUp('slow');
            item.removeClass('swipe-open')
            $(this).removeClass('swipe-open');
        } else {
            $(this).find(el).slideDown('slow');
            item.removeClass('swipe-open')
            $(this).addClass('swipe-open');
        };

        if (window.matchMedia("(max-width: 1000px)").matches) {
            let thisPrev = $(this).prev();
            let thisTop;
            if (thisPrev.length > 0) {
                thisTop = thisPrev.offset().top;
            } else {
                thisTop = $(this).offset().top - 150;
            }
            console.log(thisTop)

            $('html, body').animate({
                scrollTop: thisTop - 150 + 'px',
            },400)
        }
    });
};
