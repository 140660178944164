import './_swipe';
import './menu';
import './consulting';
import './about';
import './faq';
import './projects';

import PerfectScrollbar from 'perfect-scrollbar'
import slick from 'slick-carousel'

$(document).ready(function () {
   if (window.matchMedia("(min-width: 1000px)").matches) {
      const container = document.querySelectorAll('.scroll-content');
      const updEl = $('.update-scroll');
      if(container.length > 0) {
         for (let i = 0; i < container.length; i++) {
            let ps = new PerfectScrollbar(container[i]);
            updEl.click(function () {
               setTimeout(function() {
                  ps.update();
               }, 400)

            })
         }
      }
   }

   if (window.matchMedia("(max-width: 1000px)").matches) {
      $('.project-slider').slick({
         prevArrow: false,
         nextArrow: false,
         dots: true,
         adaptiveHeight: true
      })
   }

   let tarif = $('.tarrifs-cont');
   let overlay = $('.modal-overlay');
   let enter = $('.enter');
   $('.call-tarif').click(function () {
      $('.menu-pageIn').removeClass('menu-pageIn')
      if (tarif.hasClass('tarif-open')) {
         overlay.hide();
         tarif.removeClass('tarif-open');
      } else {
         overlay.show();
         tarif.addClass('tarif-open')
      }
   });

   $('.close-tarrifs').click(function () {
      overlay.hide();
      tarif.removeClass('tarif-open');
      enter.removeClass('tarif-open');
      $('.menu').show();
      $('.logo').show();
   });



   $('.login').click(function () {
      $('.menu-pageIn').removeClass('menu-pageIn');
      if (enter.hasClass('tarif-open')) {
         overlay.hide();
         enter.removeClass('tarif-open');
      } else {
         overlay.show();
         enter.addClass('tarif-open')
      }
   })
});

